import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';

interface FwSettingsAuth {
    // Route to the login view
    loginDestination: string;
    // Route to redirect after login
    afterLoginDestination: string;
    // Route to redirect after logout
    afterLogoutDestination: string;
    googleRecaptcha: boolean;
    googleRecaptchaSiteKey: string;
    // URLs that should not be handled by the TokenInterceptor (eg. external links)
    interceptorWhitelist: string[];
}

interface FwFileViewerFileInfoRow {
    valueTrans: string;
    field: string;
    suffix?: string;
    editable?: boolean;
    required?: boolean;
}

interface FwFileViewerFileInfo {
    rows: FwFileViewerFileInfoRow[];
}

interface FwSettingsFile {
    fileViewerFileInfo: FwFileViewerFileInfo;
}

export interface FwSettings {
    appID: string;
    // Route to navigate to when urlData decryption fails
    urlDataFallbackLink: string;
    cryptoKey: string;
    auth: FwSettingsAuth;
    file?: FwSettingsFile;
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    settingsLoaded: Subject<any> = new Subject<string>();
    frameworkSettings: FwSettings;
    jsonSettings: any;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.router.events.subscribe(res => {
            if (res instanceof NavigationEnd) {
                this.getDynamicSettings();
            }
        });
    }

    set(settings: FwSettings): void {
        this.frameworkSettings = settings;
    }

    getDynamicSettings(): void {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0'
        });
        this.http.get('./assets/settings.conf', {headers}).subscribe(response => {
            this.jsonSettings = response;

            this.settingsLoaded.next(true);
        });
    }

}
