import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../../services/settings.service';
import {Observable} from 'rxjs';

export enum FwTokenType {
    ACCESS_TOKEN = 'jwtAccessToken',
    REFRESH_TOKEN = 'jwtRefreshToken'
}

@Injectable({
    providedIn: 'root'
})
export class JwtService {
    constructor(
        private http: HttpClient,
        private settingsService: SettingsService
    ) {
    }

    setToken(item: FwTokenType, value): void {
        let token = JSON.parse(localStorage.getItem(this.settingsService.frameworkSettings.appID));

        if (!token) {
            token = {jwtAccessToken: null, jwtRefreshToken: null};
        }
        token[item] = value;

        localStorage.setItem(this.settingsService.frameworkSettings.appID, JSON.stringify(token));
    }

    getToken(item: FwTokenType): any {
        let token = JSON.parse(localStorage.getItem(this.settingsService.frameworkSettings.appID));

        return token ? token[item] : false;
    }

    /**
     * When the TokenInterceptor returns a 401 error (token invalid, expired or bearer missing) this
     * will retrieve a new access and refresh token using the existing refresh token.
     */
    refreshToken(): Observable<any> {
        const refreshToken = this.getToken(FwTokenType.REFRESH_TOKEN);
        if (refreshToken !== null) {
            return this.http
                .post('api/api.php', {
                    action: 'framework.Token/refreshToken',
                    data: {
                        refreshToken
                    },
                    componentName: 'JwtService.ts',
                    methodName: 'refreshToken()'
                })
                .pipe(
                    tap((response: any) => {
                        this.setToken(FwTokenType.REFRESH_TOKEN, response.jwt_token.refreshToken);
                        this.setToken(FwTokenType.ACCESS_TOKEN, response.jwt_token.accessToken);
                    })
                );
        }
    }
}
