import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {JwtService, FwTokenType} from './jwt.service';
import {ToastrService} from 'ngx-toastr';
import {FwUser} from '../../interfaces';
import {SettingsService} from '../../services/settings.service';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

@Injectable({
    providedIn: 'root'
})
export class UserService {

    tokenChanged: Subject<any> = new Subject<string>();
    currentUser: FwUser;

    // Used in maintenance-info component
    maintenanceActive: Subject<any> = new Subject<boolean>();
    maintenanceSet: Subject<any> = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private router: Router,
        private jwtService: JwtService,
        private toastrService: ToastrService,
        private settingsService: SettingsService
    ) {
        this.settingsService.settingsLoaded.subscribe(() => this.handleMaintenance());
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post('api/api.php', {
            action: 'framework.Auth/login',
            data: {username, password},
            componentName: 'UserService.ts',
            methodName: 'login()'
        });
    }

    logout(): Observable<any> {
        return this.http
            .post('api/api.php', {
                action: 'framework.Auth/logout',
                data: {
                    accessToken: this.jwtService.getToken(FwTokenType.ACCESS_TOKEN)
                },
                componentName: 'UserService.ts',
                methodName: 'logout()'
            })
            .pipe(
                map((response: any) => {
                    return this.logoutAutomatic(response.jwt_token.accessToken, response.jwt_token.refreshToken);
                })
            );
    }

    logoutAutomatic(accessToken: string, refreshToken: string): Observable<any> {
        this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, accessToken);
        this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, refreshToken);
        this.router.navigate([this.settingsService.frameworkSettings.auth.afterLogoutDestination]).then(() => {
            this.setNobodyUinfo();
        });
        return of(true);
    }

    /**
     * Retrieves userdata for either anonymous or logged in user
     */
    getUserData(): Observable<any> {
        return this.http
            .post('api/api.php', {
                action: 'framework.Auth/getUserData',
                data: {
                    accessToken: this.jwtService.getToken(FwTokenType.ACCESS_TOKEN),
                    refreshToken: this.jwtService.getToken(FwTokenType.REFRESH_TOKEN)
                },
                componentName: 'UserService.ts',
                methodName: 'getUserData()'
            })
            .pipe(
                map((response: any) => {
                    if (response.jwt_token) {
                        this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, response.jwt_token.accessToken);
                        this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, response.jwt_token.refreshToken);
                    }

                    if (response.status === true) {
                        this.currentUser = response.user;
                        this.currentUser.loggedIn = true;
                        this.tokenChanged.next('loggedIn');
                    } else {
                        this.setNobodyUinfo();
                        this.tokenChanged.next('anon');
                    }
                    return true;
                })
            );
    }

    newAnonymous(): Observable<any> {
        return this.http
            .post('api/api.php', {
                action: 'framework.Token/newAnonymous',
                data: {},
                componentName: 'UserService.ts',
                methodName: 'newAnonymous()'
            })
            .pipe(
                map((response: any) => {
                    this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, response.jwt_token.accessToken);
                    this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, response.jwt_token.refreshToken);
                    this.setNobodyUinfo();
                    this.tokenChanged.next('anon');
                    return true;
                })
            );
    }

    setNobodyUinfo(): void {
        const browserLanguage = this.translateService.getBrowserLang();
        this.translateService.use(browserLanguage);

        this.currentUser = null;
    }

    userDisplayName(): string {
        return this.currentUser.firstname + ' ' + this.currentUser.lastname;
    }

    private handleMaintenance(): void {
        // check if start and end are set
        if (!this.settingsService.jsonSettings.maintenance.start || !this.settingsService.jsonSettings.maintenance.end) {
            return;
        }

        // parse dates
        const start = dayjs(this.settingsService.jsonSettings.maintenance.start, 'YYYY-MM-DD HH:mm', true);
        const end = dayjs(this.settingsService.jsonSettings.maintenance.end, 'YYYY-MM-DD HH:mm', true);

        // check of both are valid
        if (!start.isValid() || !end.isValid()) {
            console.log('Maintenance dates are set but not valid!');
            return;
        }

        // check if start is before end
        if (!start.isBefore(end)) {
            console.log('Maintenance start is not before end');
            return;
        }

        this.maintenanceSet.next(dayjs().isBefore(end));

        // redirect to maintenance page if current date is between start and end
        if (dayjs().isBetween(start, end)) {
            this.maintenanceActive.next(true);
            // console.log('Maintenance is active');

            if (this.router.url !== '/error/maintenance') {
                this.router.navigate(['/error/maintenance']);
            }
        } else {
            this.maintenanceActive.next(false);
            // console.log('Maintenance is set but not active');
        }
    }
}
